import React from "react";
import backgroundImage from "../../assets/image/back.jfif"
import Navigation from "./Navigation";


const Header = ({ title, subtitle, }) => {
  return (
    <section
    className="w-full  "
      
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 30%", 
      }}
    >
        <div className=" relative grid grid-cols-1 lg:grid-cols-1 justify-center lg:h-[400.3px] h-[250px]">
        <div>
        <Navigation/>
        </div>
   
      {/* Content */}
      <div className="relative z-10 text-center text-white pt-[80px]">
        <h2 className="text-[36px] lg:text-[46px] font-albert font-extrabold leading-[55.2px] ">
          {title}
        </h2>
        <p className="mt-2 text-[15px] lg:text-[15px] font-normal font-albert leading-[25px]">
          {subtitle}
        </p>
      </div>
      </div>
    </section>
  );
};

export default Header;
