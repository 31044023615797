import React from "react";
import backgroundImage from "../../assets/image/contactwave.jfif";
// Import your icon images
import locationIcon from "../../assets/image/officecon.png"; // Add your image path
import phoneIcon from "../../assets/image/callcon.png"; // Add your image path
import supportIcon from "../../assets/image/supportcon.png"; // Add your image path

const ContactCard = ({ icon, title, content }) => (
  <div className="flex flex-col items-center text-center">
    <div className="w-[72px] h-[72px] rounded-full bg-white flex items-center justify-center mb-4 relative">
      <div className="w-[60px] h-[60px] rounded-full border-2 border-[#4CD964] flex items-center justify-center">
        <img 
          src={icon} 
          alt={title} 
          className="w-[81px] h-[81px] object-contain"
        />
      </div>
      <div className="absolute inset-0 border-2 border-[#4CD964] rounded-full"></div>
    </div>
    <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
    <p className="text-white/90 text-sm">{content}</p>
  </div>
);

const ContactInfo = () => {
  const contactDetails = [
    {
      icon: locationIcon, // Use imported image
      title: "Office Address",
      content: "17 Libreville Crescent, Abuja, Nigeria"
    },
    {
      icon: phoneIcon, // Use imported image
      title: "Make A Call",
      content: "+234 803 447 4677"
    },
    {
      icon: supportIcon, // Use imported image
      title: "24/7 Supports",
      content: "contact@greencleanhub.com"
    }
  ];

  return (
    <div className="relative w-full h-full py-8 bg-white overflow-hidden">
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center 30%", 
        }}
        className="max-w-[1250px] max-h-[311px] mx-auto py-8 rounded-xl"
      >
        <div className="relative max-w-[1250px] max-h-[225px] mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-white">
            {contactDetails.map((detail, index) => (
              <ContactCard
                key={index}
                icon={detail.icon}
                title={detail.title}
                content={detail.content}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;

