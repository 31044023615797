import Header from "../Components/Header/Header";
import EnvironmentalServices from "../Components/servicescomponent/EnvironmentalServices";

function Services() {
    return (
      <div>
        <Header
        title="Service"
        subtitle="Home > Service"
        />
        <EnvironmentalServices/>
       
      </div>
    );
  }
  
  export default Services;