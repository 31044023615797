import React from "react";
import saplingImage from "../assets/image/process-thumb.jpg.png"; // Update the path to your image

const EnvironmentalSection = () => {
  return (
    <div className="w-full px-5  mt-[-50px]">
      <div className=" max-w-[1280px] mx-auto    ">
        <div className="flex flex-col items-center md:flex-row md:p-1">
          <div className="w-full md:w-1/2 lg:w-[40%] lg:h-[536px]">
            <img
              src={saplingImage}
              alt="Sapling in hands"
              className="w-full "
            />
          </div>
          <div className=" mb-8 md:mb-0 bg-[#F6F6EE]  lg:w-[60%] px-[76px] py-[82px] mt-[-110px]">
            <h4 className="mb-2 text-lg font-semibold text-green-600 ">
              Contact Us
            </h4>
            <h1 className="mb-4 text-3xl font-bold text-gray-800">
              Fresh Environmental Plant & Safe Trees
            </h1>
            <p className="mb-6 text-gray-600">
              Competently cultivate worldwide e-tailers through
              principle-centered value. Professionally engineer high-payoff
              deliverables without exceptional processes. Rapidiously network
              cost-effective vortals.
            </p>
            <div className="">
            <button className="px-6 py-2 text-white transition duration-300 bg-green-600 rounded-md shadow-md hover:bg-green-700">
              Learn More
            </button>
            </div>
          </div>
        </div>
        {/* Image Section */}
      </div>
    </div>
  );
};

export default EnvironmentalSection;
