import React from 'react';
import { Facebook, Twitter, Instagram, PinIcon as Pinterest } from 'lucide-react';
import team1 from "../../assets/about/team1.png"
import team2 from "../../assets/about/team2.png"
import team3 from "../../assets/about/team3.png"
import backgroundImage from "../../assets/about/back.jfif"

const TeamSection = () => {
  const teamMembers = [
    {
      name: "Connie Diaz",
      role: "CEO & Founder",
      image: team1
    },
    {
      name: "Connie Diaz",
      role: "James E. Huey",
      image: team2
    },
    {
      name: "June D. Vargas",
      role: "Environmental",
      image: team3
    }
  ];

  return (
    <section
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className="w-full relative overflow-hidden py-16"
    >
      <div className="container mx-auto px-4 max-w-7xl">
        {/* Header */}
        <div className="text-center mb-24">
          <div className="inline-flex items-center gap-2 mb-4">
            <svg className="w-5 h-5 text-[#4CD964]" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3c1.988 0 3.597 1.609 3.597 3.597C13.597 8.585 11.988 10.194 10 10.194c-1.988 0-3.597-1.609-3.597-3.597C6.403 4.609 8.012 3 10 3zm0 8.194c4.418 0 8 1.79 8 4v2H2v-2c0-2.21 3.582-4 8-4z" clipRule="evenodd"/>
            </svg>
            <span className="text-[#4CD964] uppercase font-semibold text-sm tracking-wider">
              Our Team
            </span>
          </div>
          <h2 className="text-3xl md:text-4xl lg:text-[42px] font-bold text-gray-900">
            Meet Our Dedicated Members
          </h2>
        </div>

        {/* Team Members Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {teamMembers.map((member, index) => (
            <div key={index} className="relative pt-[140px]">
              {/* Circular Image with Green Border */}
              <div className="absolute left-1/2 -translate-x-1/2 -top-4 z-10">
                <div className="relative w-[280px] h-[280px]">
                  <div className="absolute inset-0 rounded-full bg-[#4CD964]" style={{
                    clipPath: "path('M140,280 C62.68,280 0,217.32 0,140 C0,62.68 62.68,0 140,0 C217.32,0 280,62.68 280,140 C280,217.32 217.32,280 140,280 Z M140,260 C206.27,260 260,206.27 260,140 C260,73.73 206.27,20 140,20 C73.73,20 20,73.73 20,140 C20,206.27 73.73,260 140,260 Z')"
                  }}></div>
                  <div className="absolute inset-[20px] overflow-hidden rounded-full">
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>

              {/* Content Card */}
              <div className="bg-[#F5F8ED] pt-36 pb-8 px-6 rounded-lg text-center">
                <h3 className="text-[26px] font-bold text-gray-900 mb-2">
                  {member.name}
                </h3>
                <p className="text-[#4CD964] text-[16px] leading-[19px] mb-6">
                  {member.role}
                </p>

                {/* Social Media Icons */}
                <div className="flex justify-center gap-4">
                  <a href="#" className="w-8 h-8 rounded-full border border-gray-200 flex items-center justify-center text-gray-400 hover:border-[#4CD964] hover:text-[#4CD964] transition-colors">
                    <Facebook className="w-4 h-4" />
                  </a>
                  <a href="#" className="w-8 h-8 rounded-full border border-gray-200 flex items-center justify-center text-gray-400 hover:border-[#4CD964] hover:text-[#4CD964] transition-colors">
                    <Twitter className="w-4 h-4" />
                  </a>
                  <a href="#" className="w-8 h-8 rounded-full border border-gray-200 flex items-center justify-center text-gray-400 hover:border-[#4CD964] hover:text-[#4CD964] transition-colors">
                    <Instagram className="w-4 h-4" />
                  </a>
                  <a href="#" className="w-8 h-8 rounded-full border border-gray-200 flex items-center justify-center text-gray-400 hover:border-[#4CD964] hover:text-[#4CD964] transition-colors">
                    <Pinterest className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;

