import React from 'react';
import ani1 from "../../assets/image/ani1.jfif"
import ani2 from "../../assets/image/ani2.jfif"
import ani3 from "../../assets/image/ani3.jfif"
import ani4 from "../../assets/image/ani4.png"
import ani5 from "../../assets/image/ani5.png"
import ani6 from "../../assets/image/ani6.png"

const Animal = () => {
  const cards = [
    {
      category: "ZOO",
      title: "Meet Our Most Unique Residents With Protection",
      description: "The African Savanna showcases the majestic lion tower",
      image: ani1
    },
    {
      category: "ANIMALS",
      title: "Monthly Features on Our Favorite Animals..",
      description: "The African Savanna showcases the majestic lion tower",
      image: ani2
    },
    {
      category: "ZOO",
      title: "Welcoming New Arrivals to Our Zoo Family..",
      description: "The African Savanna showcases the majestic lion tower",
      image: ani3
    },
    {
      category: "ECOLOGY",
      title: "It's Time to Design Your Dream Outdoor Living",
      description: "Success professions development our solutions availables sapien maximu",
      image:ani4
    },
    {
      category: "ECOLOGY",
      title: "Selecting Plant Species garden to Conserve Water",
      description: "Success professions development our solutions availables sapien maximu",
      image: ani5
    },
    {
      category: "ECOLOGY",
      title: "It's Time to Design Your Dream Outdoor Living",
      description: "Success professions development our solutions availables sapien maximu",
      image: ani6
    }
  ];

  return (
    <div className=" w-full bg-gray-50 ">
      <div className='lg:max-w-[1280px] p-8 mx-auto '>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ">
        {cards.map((card, index) => (
          <div
            key={index}
            className="group bg-[#E6E9F0] lg:max-w-[401px] lg:h-[509px] rounded-md shadow-sm overflow-hidden hover:shadow-lg transition-shadow duration-300"
          >
            <div className="relative">
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-64 object-cover"
              />
              <span className="absolute top-4 left-4 bg-[#69D94F] text-white text-sm font-semibold px-4 py-2 rounded-md">
                {card.category}
              </span>
            </div>
            <div className="p-6 space-y-4">
              <h2 className="text-[24px] text-[#000000]  font-albert leading-[28px] font-bold ">
                {card.title}
              </h2>
              <p className=" text-[16px] font-normal text-[#868681] font-albert leading-[26px]">
                {card.description}
              </p>
              <button className=" text-[16px] font-medium  font-albert leading-[26px]    h-[48px] inline-block bg-[#79B900] text-white px-8 py-3 rounded-lg hover:bg-[#5bc344] transition-colors duration-200">
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center mt-12">
        <nav className="inline-flex rounded-lg shadow-sm">
          <button
            className="px-4 py-2 border rounded-l-lg bg-white hover:bg-[#79B900] text-gray-500"
            disabled
          >
            &lt;
          </button>
          {[1, 2, 3, 4, 5, 6].map((number) => (
            <button
              key={number}
              className={`px-4 py-2 border-t border-b border-r border-gray-200 bg-white hover:bg-[#79B900]
                ${number === 1 ? 'text-[#69D94F] font-semibold' : 'text-gray-500'}`}
            >
              {number}
            </button>
          ))}
          <button
            className="px-4 py-2 border rounded-r-lg bg-white hover:bg-[#79B900] text-gray-500"
          >
            &gt;
          </button>
        </nav>
      </div>
      </div>
    </div>
  );
};

export default Animal;

