import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/image/Green_Clean logo.png";
import logoTwo from "../../assets/image/Green_Cleanside.png";
import { useState, useEffect } from "react";
import Top from "./Top";

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation(); // Get current route

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getLinkClass = (path) =>
    `hover:text-[#49AF45] ${
      location.pathname === path ? "text-[#49AF45] font-bold" : "text-white"
    }`;

  return (
    <div
      className={`w-full fixed ${
        isScrolled ? "bg-[#276125]" : "bg-transparent"
      } transition-all duration-300 z-30`}
    >
      <Top />
      <div className="border-t border-b border-[#868681]">
        {/* Container for all navigation elements */}
        <div className="mx-auto flex items-center justify-between py-5 text-white max-w-[1280px] px-5">
          {/* Logo Section */}
          <div className="flex items-center space-x-3">
            <img
              src={logo}
              alt="Green Clean Logo"
              className="h-[46px]  w-[56px]"
            />
            <img
              src={logoTwo}
              alt="Green Clean Logo"
              className="h-[36px] w-[176px] lg:block"
            />
          </div>

          {/* Desktop and Tablet Navigation Links */}
          <div className="hidden text-sm font-medium lg:flex lg:space-x-4 xl:space-x-10 lg:text-base 2xl:text-lg font-albert">
            <Link to="/" className={getLinkClass("/")}>
              Home
            </Link>
            <Link to="/About" className={getLinkClass("/About")}>
              About
            </Link>
            <Link to="/services" className={getLinkClass("/services")}>
              Services
            </Link>
            <Link to="/ourimpact" className={getLinkClass("/ourimpact")}>
              Our Impact
            </Link>
            <Link to="/events" className={getLinkClass("/events")}>
              Events
            </Link>
            <Link to="/getinvolved" className={getLinkClass("/getinvolved")}>
              Get Involved
            </Link>
          </div>

          {/* Search Icon and Contact Button */}
          <div className="flex items-center space-x-4 lg:space-x-6">
            <button className="hidden lg:block px-4 py-2 bg-[#49AF45] text-white rounded-md hover:bg-[#30862d] font-albert">
              <Link to="/contact">Contact Us</Link>
            </button>
          </div>

          {/* Mobile Hamburger Icon */}
          <div className="lg:hidden">
            {isMenuOpen ? (
              <FaTimes
                className="text-2xl text-white cursor-pointer"
                onClick={toggleMenu}
              />
            ) : (
              <FaBars
                className="text-2xl text-white cursor-pointer"
                onClick={toggleMenu}
              />
            )}
          </div>
        </div>

        {isMenuOpen && (
          <div className="absolute top-[115px] left-0 right-0 bg-white bg-opacity-90 z-50 py-3 space-y-4 text-center">
            <Link
              to="/"
              className={`block text-black hover:text-[#49AF45] ${
                location.pathname === "/" ? "font-bold text-[#49AF45]" : ""
              }`}
            >
              Home
            </Link>
            <Link
              to="/About"
              className={`block text-black hover:text-[#49AF45] ${
                location.pathname === "/About" ? "font-bold text-[#49AF45]" : ""
              }`}
            >
              About
            </Link>
            <Link
              to="/services"
              className={`block text-black hover:text-[#49AF45] ${
                location.pathname === "/services"
                  ? "font-bold text-[#49AF45]"
                  : ""
              }`}
            >
              Services
            </Link>
            <Link
              to="/ourimpact"
              className={`block text-black hover:text-[#49AF45] ${
                location.pathname === "/ourimpact"
                  ? "font-bold text-[#49AF45]"
                  : ""
              }`}
            >
              Our Impact
            </Link>
            <Link
              to="/events"
              className={`block text-black hover:text-[#49AF45] ${
                location.pathname === "/events"
                  ? "font-bold text-[#49AF45]"
                  : ""
              }`}
            >
              Events
            </Link>
            <Link
              to="/getinvolved"
              className={`block text-black hover:text-[#49AF45] ${
                location.pathname === "/getinvolved"
                  ? "font-bold text-[#49AF45]"
                  : ""
              }`}
            >
              Get Involved
            </Link>
            <button className="w-full px-4 py-2 bg-[#49AF45] text-white rounded-md hover:bg-[#30862d]">
              <Link to="/contact">Contact Us</Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navigation;
