import TeamSection from "../Components/Aboutcomponent/TeamSelection";
import Header from "../Components/Header/Header";
import EcoEnvironmentalPage from "../Components/ourimpactcomponent/AllTogether";


function OurImpact() {
    return (
      <div>
        <Header 
        title="Our Impact"
        subtitle="Home > Our Impact"
        />
        
        <EcoEnvironmentalPage/>
       
      </div>
    );
  }
  
  export default OurImpact;