const BannerSection = () => {
    return (
      <section className="w-full bg-[#49AF45]  h-[200px] py-32 md:py-48">
        <div className="max-w-[1280px]   mx-auto px-5">
          <h1
            className="lg:text-[65px] font-bold text-center uppercase tracking-wide"
            style={{
              WebkitTextStroke: '3px white', // Adjusted for a clearer outline
              WebkitTextFillColor: 'transparent', // Ensures text remains outlined
              textTransform: 'uppercase',
              fontFamily: "'Albert Sans', sans-serif", // Replace with correct font if needed
              letterSpacing: '0.1em', // Adjusted for better spacing
            }}
          >
            Ultimate Battle Victorious
          </h1>
        </div>
      </section>
    );
  };
  
  export default BannerSection;
  