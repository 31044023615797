import Animal from "../Components/Eventcomponent/Animal";
import Header from "../Components/Header/Header";

function Events() {
    return (
      <div>
        <Header
        title="Events"
        subtitle="Home > Events"
        />
        <Animal/>
       
      </div>
    );
  }
  
  export default Events;