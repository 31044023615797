import React from "react";
import girl from "../assets/image/girr.png";
import plant from "../assets/image/payara-1.png.png";
import { FaPlay } from "react-icons/fa";
import bg from "../assets/image/Overlay.png";
import leee from "../assets/image/leaf2.png.png";
import rees from "../assets/image/renew.png";
import tee from "../assets/image/hand.png";
import leaf from "../assets/image/leaf.png";
import backgroundImage from "../assets/image/Services.png";

function Knowclean() {
  return (
    <div
      className="w-full overflow-hidden"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div
        className="absolute right-0 z-20 transform -translate-y-1/2 top-1/2"
        style={{
          right: "0",
          top: "60%",
          transform: "translateY(-50%)",
          zIndex: 20,
        }}
      >
        <img src={leaf} alt="Leaf" className="w-[40px] h-auto" />
      </div>
      <div className="grid items-center grid-cols-1 gap-2 px-5 py-14 lg:grid-cols-2 max-w-[1280px] mx-auto">
        {/* Left Section: Image and Video */}
        <div className="relative">
          <div className="relative z-10">
            <img
              src={girl}
              alt="Volunteer"
              className=" sm:w-[400px] md:w-[498px] h-auto "
            />
          </div>
          <div className="absolute bottom-0 z-20 w-3/5 sm:left-[20%] md:left-64">
            <img src={plant} alt="Planting" className="shadow-lg" />
          </div>
          {/* Play Button */}
          <div className="absolute inset-0 z-20 flex items-center justify-center -top-80 lg:-right-[400px] md:-right-[231px] -right-[331px] sm:right-0 lg:top-[-70%]">
            {/* Center the overlay and play button */}
            <div className="flex items-center justify-center w-[150px] h-[150px] border-[#49AF45] border-dotted border-4 rounded-full relative">
              {/* Overlay Image */}
              <img
                src={bg}
                alt="Overlay"
                className="absolute inset-0 object-cover w-[120px] h-[120px] mt-3 ml-3 rounded-full"
              />
              {/* Play Button Inside Overlay */}
              <div className="absolute flex items-center justify-center p-4 text-white bg-[#49AF45] rounded-full shadow-lg cursor-pointer w-[90px] h-[90px] border-white border-[4px]">
                <FaPlay className="text-2xl" />
              </div>
            </div>
          </div>
        </div>

        {/* Right Section: Text Content */}
        <div className="lg:w-[605px] w-full font-albert lg:ml">
          <div className="flex items-center gap-3 mb-3">
            <img src={leee} alt="Planting" className="w-[20px] h-[20px]" />
            <p className="mb-2 text-[#49AF45] text-[16px] font-[500] uppercase">
              Get to know Green Claws
            </p>
          </div>
          <h2 className="mb-3 text-[46px] leading-[55.2px] font-bold text-[#001819] sm:text-[36px] md:text-[46px]">
            Environmental Sustainable Forever Green Future
          </h2>
          <p className="mb-6 text-[#868691] text-[16px] leading-[26px] sm:text-[14px] md:text-[16px]">
            Assertively reinvent front-end deliverables via to sustainable
            products. Credibly an expanded array of vortals for open-source
            internal or "organic" sources main negotiate professional supply
            chains via inexpensive
          </p>
          {/* Features */}
          <div className="grid grid-cols-1 gap-4 mb-[40px] mt-[28px] lg:grid-cols-2 md:grid-cols-2">
            <div className="flex space-x-4">
              <div>
                <img src={rees} alt="Planting" className="w-[70px] h-[60px]" />
              </div>
              <div>
                <h4 className="font-semibold text-[#001819] text-[22px] leading-[26.4px] sm:text-[18px]">
                  Dust Recycling
                </h4>
                <p className="text-sm text-[#868681] text-[17px] leading-[26px] sm:text-[14px]">
                  Alternative innovation in environmental.
                </p>
              </div>
            </div>
            <div className="flex space-x-4">
              <div>
                <img src={tee} alt="Planting" className="w-[70px] h-[60px]" />
              </div>
              <div>
                <h4 className="font-semibold text-[#001819] text-[22px] leading-[26.4px] sm:text-[18px]">
                  Safe Environment
                </h4>
                <p className="text-sm text-[#868681] text-[17px] leading-[26px] sm:text-[14px]">
                  Alternative innovation in environmental.
                </p>
              </div>
            </div>
          </div>
          {/* Button */}
          <button className="px-[35px] py-[16px] text-white transition bg-[#49AF45] rounded-sm text-[16px] leading-[26px] font-[500] hover:bg-[#389b32]">
            Explore More
          </button>
        </div>
      </div>
    </div>
  );
}

export default Knowclean;
