"use client"

import React from "react";
import { useLoadScript, GoogleMap } from "@react-google-maps/api";
import backgroundImage from "../../assets/image/contactsec.jfif"

const ContactSection = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  });

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
    minHeight: "500px",
  };

  const center = {
    lat: 20, // Center of the world map
    lng: 0,
  };

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <section
   
     className=" w-full  bg-white py-16">
      <div 
       style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 30%", 
      }}
      className="max-w-[1250px] mx-auto px-4">
        <div className=" max-w-[1210px] grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Map Section */}
          <div className="relative rounded-lg overflow-hidden shadow-lg w-[540px] h-[628px]">
            {!isLoaded ? (
              <div className="w-full h-full bg-gray-100 animate-pulse" />
            ) : (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={2}
                center={center}
                options={{
                  styles: [
                    {
                      featureType: "water",
                      elementType: "geometry",
                      stylers: [{ color: "#a3ccff" }],
                    },
                    {
                      featureType: "landscape",
                      elementType: "geometry",
                      stylers: [{ color: "#f5f5f0" }],
                    },
                  ],
                }}
              />
            )}
          </div>

          {/* Contact Form */}
          <div className=" bg-transparent p-8">
            <div className="mb-8">
              <div className="flex items-center gap-2 mb-2">
                <span className="text-[#4CD964]">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <h5 className="text-sm font-semibold text-[#4CD964]">Get In Touch</h5>
              </div>
              <h2 className="text-3xl font-bold text-gray-900">Writing us something</h2>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
           
              <div className=" w-full lg:w-[530px] grid grid-cols-2 md:grid-cols-2 gap-6">
                <div>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name*"
                    className="w-[277.5px] px-4 py-3  bg-transparent border border-gray-300 focus:border-[#4CD964] focus:ring-1 focus:ring-[#4CD964] outline-none transition-colors placeholder-gray-500"
                    required
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email*"
                    className="w-[277.5px] px-4 py-3  bg-transparent border border-gray-300 focus:border-[#4CD964] focus:ring-1 focus:ring-[#4CD964] outline-none transition-colors placeholder-gray-500"
                    required
                  />
                </div>
              </div>
              <div className=" w-full lg:w-[530px] grid grid-cols-2 md:grid-cols-2 gap-6">
                <div>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone No"
                    className="w-[277.5px] px-4 py-3  bg-transparent border border-gray-300 focus:border-[#4CD964] focus:ring-1 focus:ring-[#4CD964] outline-none transition-colors placeholder-gray-500"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="Subject*"
                    className="w-[277.5px] px-4 py-3 bg-transparent border border-gray-300 focus:border-[#4CD964] focus:ring-1 focus:ring-[#4CD964] outline-none transition-colors placeholder-gray-500"
                    required
                  />
                </div>
              </div>
              <div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  rows={6}
                  className=" w-full lg:w-[520px] px-2 py-3 rounded-lg bg-transparent border border-gray-300 focus:border-[#4CD964] focus:ring-1 focus:ring-[#4CD964] outline-none transition-colors placeholder-gray-500"
                />
              </div>
              <button
                type="submit"
                className="lg:w-[198px] w-full bg-[#4CD964] hover:bg-[#3fb853] text-white font-semibold py-3 px-6  transition-colors"
              >
                Send Message
              </button>
             
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

