import ContactInfo from "../Components/contactcomponent/contactinfo";
import ContactSection from "../Components/contactcomponent/contactsection";
import Header from "../Components/Header/Header";

function Contact() {
    return (
      <div className="">
       <Header
       title="Contact "
       subtitle="Home > Contact "
       />
       <ContactInfo/>
       <ContactSection/>
      </div>
    );
  }
  
  export default Contact;