import React from "react";
import { Recycle, Droplet, Cloud, Leaf, Wind, Thermometer } from "lucide-react";
import conarr from "../../assets/image/Border.png"
import serv1 from "../../assets/serv/serv1.png"
import serv2 from "../../assets/serv/serv2.png"
import serv3 from "../../assets/serv/serv3.png"
import serv4 from "../../assets/serv/serv4.png"
import serv5 from "../../assets/serv/serv5.png"
import serv6 from "../../assets/serv/serv6.png"
import left from "../../assets/serv/servleft.png"

const services = [
  {
    title: "Dust Recycling",
    icon: <Recycle className="w-6 h-6 text-white" />,
    image: serv1,
    description: "Alternative innovation to ethical network environmental whiteboard",
  },
  {
    title: "Cleaning Ocean",
    icon: <Droplet className="w-6 h-6 text-white" />,
    image: serv2,
    description: "Alternative innovation to ethical network environmental whiteboard",
  },
  {
    title: "Carbon Offsetting",
    icon: <Cloud className="w-6 h-6 text-white" />,
    image: serv3,
    description: "Alternative innovation to ethical network environmental whiteboard",
  },
  {
    title: "Tree Plants",
    icon: <Leaf className="w-6 h-6 text-white" />,
    image: serv4,
    description: "Alternative innovation to ethical network environmental whiteboard",
  },
  {
    title: "Renewable Energy",
    icon: <Wind className="w-6 h-6 text-white" />,
    image: serv5,
    description: "Alternative innovation to ethical network environmental whiteboard",
  },
  {
    title: "Global Warming",
    icon: <Thermometer className="w-6 h-6 text-white" />,
    image: serv6,
    description: "Alternative innovation to ethical network environmental whiteboard",
  },
];
const ServiceCard = ({ service }) => (
  <div className="group bg-[#FFFFFF]  lg:max-w-[405px]   lg:h-[530px] rounded-lg shadow-md overflow-hidden">
    <div className="relative">
      <img
        src={service.image}
        alt={service.title}
        className="w-full h-[300px] object-cover"
      />
      <div className="absolute -bottom-5 left-5">
        <div className="w-14 h-14 rounded-full bg-[#4CD964] flex items-center justify-center shadow-lg">
          {service.icon}
        </div>
      </div>
    </div>
    <div className="p-6 pt-10">
      <div></div>
      <h3 className="text-xl font-bold text-gray-900 mb-2">{service.title}</h3>
      <div className="relative -top-2  lg:-left-40  -left-36  w-[30px] h-0.5 bg-[#49AF45] mx-auto mt-4"></div>
      
      <p className="text-gray-500 mb-4">{service.description}</p>
     
    </div>
    <div className="flex items-center w-[100%] justify-between border-t-2 border-[#F5F5F0]">
        <span className=" px-10 text-[#1E1E1E] text-[16px] font-albert leading-[25px] font-semibold">Discover More</span>
        <div className="w-10 h-10 flex items-center justify-center transition-transform duration-200 transform group-hover:translate-x-1">
          <img
            src={conarr} // Replace with the correct path to the uploaded image
            alt="Arrow"
            className="w-[44px] h-[44px]"
          />
        </div>
      </div>
  </div>
);


const EnvironmentalServices = () => {
  return (
    <section className="w-full  bg-[#F5F5F0]">
      <div className="lg:max-w-[1280px] py-16 mx-auto px-6">
        {/* Header Section */}
              <div className="text-center mb-16">
        <div className="inline-flex items-center gap-2 mb-4">
          <img src={left} alt="" className="w-5 h-5" />
          <span className="text-[#4CD964] uppercase font-semibold text-sm tracking-wider">
            Our Services
          </span>
        </div>
        <h2 className="text-3xl md:text-4xl lg:text-[42px] font-bold leading-tight mt-4 text-gray-900">
          Grean Clean Provides Environment <br className="hidden md:inline" />
          Best Leading Services
        </h2>
        
      </div>

        {/* Service Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default EnvironmentalServices;
