import home from "../../assets/about/home.png";
import epe from "../../assets/about/epe.png";
import amaLogo from "../../assets/about/ama.png";
import envatoLogo from "../../assets/about/ene.png";

const PartnerLogos = () => {
  const logos = [
    { id: 1, src: envatoLogo, alt: "Envato Logo" },
    { id: 2, src: epe, alt: "EPEE Logo" },
    { id: 3, src: home, alt: "Eco Home Logo" },
    { id: 4, src: amaLogo, alt: "Amazon Logo" },
  ];

  return (
    <section className="bg-white py-8">
      <div className="max-w-[1280px] mx-auto px-4">
        <div className="flex justify-between items-center gap-8 flex-wrap">
          {logos.map((logo) => (
            <div
              key={logo.id}
              className="flex-1 min-w-[113px] flex justify-center items-center"
            >
              <img
                src={logo.src}
                alt={logo.alt}
                className="h-12 md:h-16 object-contain hover:opacity-80 transition-opacity w-[113px]  space-y-4"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnerLogos;

