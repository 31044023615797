import React, { useState } from 'react';
import { Star } from 'lucide-react';
import backgroundImage from "../../assets/about/bgabout.jfif";
import left from "../../assets/serv/servleft.png";
import client1 from "../../assets/about/client1.png";
import client2 from "../../assets/about/client2.png";
import amaLogo from "../../assets/about/ama.png"; // Amazon logo
import envatoLogo from "../../assets/about/ene.png"; // Envato logo

const testimonials = [
  {
    id: 1,
    name: "John D. Alexon",
    role: "Web Developer",
    companyLogo: amaLogo, // Correct company logo path
    image: client1,
    rating: 5,
    quote: "Competently cultivate worldwide e-tailers through to principles professionally engineer high-payoff deliverables without exception. Rapidiously network effectively.",
  },
  {
    id: 2,
    name: "Ahiduzzaman",
    role: "Web Developer",
    companyLogo: envatoLogo, // Correct company logo path
    image: client2,
    rating: 5,
    quote: "Competently cultivate worldwide e-tailers through to principles professionally engineer high-payoff deliverables without exception. Rapidiously network effectively.",
  },
];

const StarRating = ({ rating }) => {
  return (
    <div className="flex gap-1 mb-4">
      {[...Array(rating)].map((_, i) => (
        <Star key={i} className="w-5 h-5 fill-[#4CD964]" />
      ))}
    </div>
  );
};

const TestimonialCard = ({ testimonial }) => (
  <div className="flex flex-col items-start max-w-xl">
    <div className="flex items-center gap-4 mb-6">
      <img
        src={testimonial.image}
        alt={testimonial.name}
        className="w-20 h-20 rounded-full object-cover"
      />
      <StarRating rating={testimonial.rating} />
    </div>
    <blockquote className="text-gray-600 text-lg leading-relaxed mb-8">
      "{testimonial.quote}"
    </blockquote>
    <div className="flex items-center justify-between w-full">
      <div>
        <h3 className="text-xl font-bold text-gray-900">{testimonial.name}</h3>
        <p className="text-gray-500">{testimonial.role}</p>
      </div>
      <img
        src={testimonial.companyLogo}
        alt={`${testimonial.name}'s company`}
        className="h-8"
      />
    </div>
  </div>
);

const TestimonialsSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <section 
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className="py-20 relative overflow-hidden"
    >
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center gap-2 mb-4">
            <img src={left} alt="" className="w-5 h-5" />
            <span className="text-[#4CD964] uppercase font-semibold text-sm tracking-wider">
              TESTIMONIALS
            </span>
          </div>
          <h2 className="text-3xl md:text-4xl lg:text-[42px] font-bold leading-tight mt-4 text-gray-900">
            Clients Best Feedback About <br className="hidden md:inline" />
            Green Clean
          </h2>
        </div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-6xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={testimonial.id} testimonial={testimonial} />
          ))}
        </div>

        {/* Navigation Dots */}
        <div className="flex justify-center gap-2 mt-12">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveSlide(index)}
              className={`w-3 h-3 rounded-full transition-colors ${
                activeSlide === index ? 'bg-[#4CD964]' : 'bg-gray-300'
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
