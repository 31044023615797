import { BrowserRouter, Route, Routes } from "react-router-dom";
import RootLayout from "./Layout/RootLayout";
import Home from "./Page/Home/Home";
import About from "./Page/About";
import Services from "./Page/Services";
import Contact from "./Page/Contact";
import Events from "./Page/Events";
import OurImpact from "./Page/OurImpact";
import ScrollToTop from "./Components/ScrollToTop";
// Adjust the path to your components folder

function App() {
  return (
    <div>
      <BrowserRouter>
        {/* ScrollToTop ensures the page scrolls to the top on route changes */}
        <ScrollToTop />
        <div className="h-dvh">
          <Routes>
            <Route path="/" element={<RootLayout />}>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/events" element={<Events />} />
              <Route path="/ourimpact" element={<OurImpact />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
