import BannerSection from "../Components/Aboutcomponent/BannerSection";
import PartnerLogos from "../Components/Aboutcomponent/Partners";
import TeamSection from "../Components/Aboutcomponent/TeamSelection";
import TestimonialsSection from "../Components/Aboutcomponent/TestimonialsSection";
import Header from "../Components/Header/Header";
import StatsAndProcess from "../Components/Stats/Stats";
import EnvironmentalSection from "../section/Environment";

import Knowclean from "../section/Knowclean";

function About() {
  return (
    <div>
      <Header title="About Us" subtitle="Home > About Us" />
      <Knowclean />
      <StatsAndProcess />
      <EnvironmentalSection />
      <PartnerLogos/>
      <TeamSection />
      <BannerSection/>
      <TestimonialsSection/>
    </div>
  );
}

export default About;
