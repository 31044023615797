import React from 'react';
import { ArrowRight, Download, Check, Phone, Recycle, Leaf } from 'lucide-react';
import waste from "../../assets/image/wasteour.jfif"
import backgroundImage from "../../assets/about/whatlef.jfif"
import serv6 from "../../assets/serv/serv6.png"
import serv3 from "../../assets/serv/serv3.png"
import eco  from "../../assets/about/eco.png"
import whatapp from "../../assets/about/whatapp.png"


export default function EcoEnvironmentalPage() {
  const benefits = [
    {
      title: "Renewable Energy",
      image: serv6,
      features: ["New Modern Equipments", "Expert's Volunteers"]
    },
    {
      title: "Renewable Energy",
      image: serv3,
      features: ["New Modern Equipments", "Expert's Volunteers"]
    }
  ];

  const services = [
    {
      title: "Cleaning Ocean",
      icon: Recycle,
      description: "Ethical network environmental architect go forward opportu credibly implement"
    },
    {
      title: "Plant Seedlings",
      icon: Leaf,
      description: "Ethical network environmental architect go forward opportu credibly implement"
    }
  ];

  return (
    // page div
    <div className=" w-full  h-full ">
        {/* general div */}

        <div className='w-full lg:max-w-[1440px]  h-full lg:max-h-[2048.5px] mx-auto px-4 '>

         <div className=' w-full lg:max-w-[1330px] h-full lg:max-h-[1828.5px] mx-auto px-8'>

      {/* First Section - Main Content */}
      <div className="grid gap-28 md:gap-80 py-8  w-full  lg:grid-cols-2 lg:gap-12">
        {/* Left Column */}
        <div className="space-y-6">
          <div className="relative w-[300px]  md:w-full md:h-full lg:w-[750px] h-[300px] lg:h-[550px] ">
            <img
              src={waste}
              alt="Volunteers in green shirts recycling"
              className="h-full w-full object-cover"
            />
          </div>
          <div className='lg:w-[750px] lg:h-[289px] w-full h-full'>
          <h1 className="text-[36px] lg-w-[450px] font-albert font-bold leading-[43.2px] tracking-tight  ">
            Rapidiously Formulate efficient
          </h1>
          <div className="space-y-4 lg:w-[750px] font-albert  text-[16px] leading-[26px] text-[#868681]">
            <p>
              Alternative innovation to ethical network environmental whiteboard pursue compelling results for premier methods
              empowerment. Dramatically architect go forward opportunities before user-centric partnerships. Credibly
              implement exceptional
            </p>
            <p>
              Continually fashion of more orthogonal leadership skills whereas wireless metrics. Uniquely syndicate
              exceptional opportunities with more interdependent users. Globally enhance fully tested meta-services rather
              than pandemic . Proactively integrate client-integrate go forw more thanoptins architectures and turnkey
              meta-services. harness integrated ROI whereas frictionless products.
            </p>
          </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-8 lg:left-40 relative w-full lg:w-[424px] h-full">
          {/* Categories Section */}
          <div className=" lg:h-[480px]    h-[430px]  md:h-[470px]  md:w-full  w-[290px] lg:w-[421px] bg-[#F7F5ED]">
            
            <div className="space-y-2 relative lg:w-[346px]  md:w-[80%]  w-[270px] md:h-[300px] mx-auto py-10  ">
            <h2 className="text-2xl font-semibold">Categories</h2>
              {[
                "Renewable Energy",
                "Environmental",
                "Plant Seedlings",
                "Dust Recycling",
                "Ocean Cleaning",
              ].map((category) => (
                <a
                  key={category}
                  href="#"
                  className="flex items-center justify-between rounded-lg border bg-white p-4 transition-colors hover:bg-gray-50"
                >
                  <span>{category}</span>
                  <ArrowRight className="h-5 w-5 text-gray-400" />
                </a>
              ))}
            </div>
          </div>

          {/* Downloads Section */}
          <div className="space-y-4 lg:w-[424.61px] gap-6 w-[280px]  md:w-full md:h-[250px]  h-[200px] lg:h-[280px] bg-[#F7F5ED] relative lg:top-8">
          
            <div className="space-y-3 w-[200px] h-[40px]    md:w-[80%]  lg:w-[346px] lg:h-[57px] mx-auto">
            <h2 className="text-2xl font-semibold">Downloads</h2>
              {[
                { title: "Service Report", icon: Download },
                { title: "Download Lists", icon: Download },
              ].map((item) => (
                <button
                  key={item.title}
                  className="flex w-full items-center justify-between rounded-lg bg-[#001F0F] p-4 text-white transition-colors hover:bg-[#001F0F]/90"
                >
                  <span>{item.title}</span>
                  <item.icon className="h-5 w-5" />
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

         {/* Second Section - Services and Contact */}
         <section className= " py-16 lg:py-2">
        <div className="relative w-full   h-full grid gap-10  lg:grid-cols-2  lg:gap-10">

            <div className=' w-full lg:w-[770px]   lg:max-h-[450px] grid lg:grid-cols-2  gap-10 lg:gap-1'>
          {/* Service Cards */}
          
          {services.map((service, index) => (
            <div 
              key={index}
              className=" lg:w-[368px]  lg: h-[183px] flex items-start  bg-[#F7F5ED] p-6"
            >
              <div className="   rounded-full bg- p-3">
                <service.icon className="h-6 w-6 text-green-500" />
              </div>
              <div>
                <h3 className="mb-2 text-lg font-semibold">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            </div>
          ))}

          

          {/* ECO Banner */}
          <div className="col-span-full  h-full lg:h-[120px] mt-2 flex items-center gap-4  bg-green-500 p-2 text-white">
            <div className="flex h-12 w-12 items-center justify-center rounded-full bg-white/10">
            <img
              src={eco}
              alt="Volunteers in green shirts recycling"
              className="h-full w-full object-cover"
            />
            </div>
            <p className="text-lg italic">
              Competently architect intermandated deliverables client niches continually underwhelm
            </p>
          </div>
          </div>

            {/* Contact Card - Absolute positioned on larger screens */}
        <div
        style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center 30%", 
              }}
         className="  top-0 w-full lg:w-[380px] relative lg:left-[190px] lg:h-[435px] space-y-10 h-full rounded-lg  p-6 text-white lg:block">
            <div className="mb-6 flex   ">
              <div className="flex h-16 w-16 items- justify-start rounded-full bg-green-400  ">
              <img
              src={whatapp}
              alt="Volunteers in green shirts recycling"
              className="h-full w-full object-cover"
            />
              </div>
            </div>
            <div className="space-y-2 ">
              <h3 className="text-xl font-medium">Call Us Anytime</h3>
              <p className="text-2xl font-semibold">+234 803 447 4677</p>
              <p className="text-gray-300">contact@greencleanhub.com</p>
             
            </div>
            <button className="flex w-full items-center justify-center gap-2 rounded-lg bg-green-500 px-6 py-3 font-medium transition-colors hover:bg-green-600">
                Contact Us
                <ArrowRight className="h-5 w-5" />
              </button>
          </div>

         
        </div>

      
      </section>

      {/* Third Section - Benefits */}
      <section className="lg:-top-10 relative">
        <div className="space-y-6 w-full lg:w-[780px] h-full ">
          <div className="space-y-4 w-full lg:max-w-[750px]">
            <h2 className="lg:text-[36px] text-[26px] md:text-[26px]  leading-[43px]  font-albert font-bold">What the Benefits?</h2>
            <p className="leading-[26px]  text-[16px] font-normal font-albert text-gray-600">
              Alternative innovation to ethical network environmental whiteboard pursue compelling results for premier methods
              empowerment. Dramatically architect go forward opportunities
            </p>
          </div>

          <div className=" w-full lg:w-[750px] lg:h-[380px] grid gap-6 md:grid-cols-2">
            {benefits.map((benefit, index) => (
              <div 
                key={index} 
                className="relative w-full  lg:w-[340px] lg:h-[320px] overflow-hidden rounded-lg  "
              >
                <div className="relative h-48 overflow-hidden">
                  <img
                    src={benefit.image}
                    alt={benefit.title}
                    className="h-full w-full object-cover"
                  />
                  {index === 0 && (
                    <>
                      <div className="absolute left-4 top-4 h-12 w-12 rounded-full border-2 border-white/30 bg-white/10" />
                      <div className="absolute right-8 bottom-8 h-16 w-16 rounded-full border-2 border-white/30 bg-white/10" />
                    </>
                  )}
                </div>
                <div className=" bg-white w-[80%] mx-auto relative -top-6 rounded-lg shadow-sm lg:h-[280px] p-6">
                  <h3 className="mb-4 text-[22px] font-albert leading-[26px] font-semibold">{benefit.title}</h3>
                  <ul className="space-y-2 0">
                    {benefit.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center gap-2">
                        <Check className="h-5 w-5 text-green-500" />
                        <span className="text-[16px] leading-[26px]  font-albert font-normal text-[#868681]">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      </div>

      </div>

   
    </div>
  );
}

